<template>
	<div>
		<div class="page-section">
			<div class="page-section__inner gr gr--wrap">
				<div class="col col--1@tablet hide show@tablet"></div>
				<div
					class="col col--12 col--10@tablet page-section__card page-section__card--light"
				>
					<SuccessMessage key="success" v-if="showSuccessMessage" />

					<ContactForm key="form" v-else />

					<div class="page-section__card__social">
						<h3 class="subtitle subtitle--big">
							Social channels
						</h3>
						<div class="item__value">
							<div key="twitter" class="item__value--social">
								<img
									src="@/assets/imgs/social/twitter__green.svg"
									alt="twitter icon"
								/>
								<VLink
									size="small"
									target="_blank"
									href="https://twitter.com/takwimuafrica?lang=en"
								>
									Twitter
								</VLink>
							</div>
							<div key="facebook" class="item__value--social">
								<img
									src="@/assets/imgs/social/facebook__green.svg"
									alt="facebook icon"
								/>
								<VLink
									size="small"
									target="_blank"
									href="https://www.facebook.com/TakwimuAfrica/"
								>
									Facebook
								</VLink>
							</div>
						</div>
					</div>
				</div>
				<div class="col col--1@tablet hide show@tablet"></div>
			</div>
		</div>
	</div>
</template>

<script>
import ContactForm from '@/pages/ContactUsPage/ContactForm';
import SuccessMessage from '@/pages/ContactUsPage/SuccessMessage';

export default {
	name: 'ContactUsPage',
	components: {
		ContactForm,
		SuccessMessage
	},
	data() {
		return {
			showSuccessMessage: false
		};
	},
	methods: {
		toggle() {
			this.showSuccessMessage = !this.showSuccessMessage;
		}
	}
};
</script>
