<template>
	<div>
		<VHeading level="2" classes="page-section__card__title">
			Contact Us
		</VHeading>

		<form
			class="form__column page-section__card__light"
			@submit.prevent="submit"
		>
			<div class="page-section__card__block__right no--padding-b">
				<div>
					<VDefaultField
						label="Full name"
						classes="space--big"
						label-classes="form__label-field"
						:error="errors.get('fullName')"
					>
						<template #field>
							<VInput
								v-model="form.fullName"
								type="text"
								placeholder="Please enter your full name"
								:error="errors.get('fullName')"
								@input="errors.clear('fullName')"
							/>
						</template>
					</VDefaultField>
					<VDefaultField
						label="Email"
						classes="space--big"
						label-classes="form__label-field"
						:error="errors.get('email')"
					>
						<template #field>
							<VInput
								v-model="form.email"
								type="email"
								placeholder="Please enter your email"
								:error="errors.get('email')"
								@input="errors.clear('email')"
							/>
						</template>
					</VDefaultField>
				</div>
			</div>
			<VDefaultField
				label="Message"
				classes="space--big"
				label-classes="form__label-field"
				:error="errors.get('message')"
			>
				<template #field>
					<VTextarea
						v-model="form.message"
						type="email"
						placeholder="Please enter your message"
						:error="errors.get('message')"
						@input="errors.clear('message')"
					/>
				</template>
			</VDefaultField>
			<VButton
				color="outline-black"
				classes="page-section__card__button"
				type="submit"
			>
				Submit
			</VButton>
		</form>
	</div>
</template>

<script>
import { SEND_MESSAGE } from '@/store/actions.type';

import Errors from '@/helpers/Errors';

export default {
	data() {
		return {
			form: {
				fullName: '',
				email: '',
				message: ''
			},
			errors: new Errors()
		};
	},
	methods: {
		async submit() {
			try {
				await this.$store.dispatch(SEND_MESSAGE, this.form);
				this.resetForm();
				this.$parent.toggle();
			} catch (errors) {
				this.errors.record(errors.response.data.errors);
			}
		},
		resetForm() {
			this.form.fullName = '';
			this.form.message = '';
			this.form.email = '';
		}
	}
};
</script>
